import React from "react";
import "./styles.css"
import * as axios from "axios";

export default class Orders extends React.Component {

  state = {
    orders: [],
    loading: false,
    page: 0,
    maxPage: 100,
    canPreviousPage: false,
    canNextPage: true
  };

  componentDidMount() {
    this.getOrders(0);
    this.setState({
      darkMode: localStorage.getItem('darkMode') === 'true'
    })
  }

  translateStatus = (status) => {
    switch (status) {
      case 'created':
        return 'Creată';
      case 'accepted':
        return 'Acceptată';
      case 'prepared':
        return 'Preparată';
      case 'picked':
        return 'Ridicată';
      case 'delivered':
        return 'Livrată';
      case 'canceled':
        return 'Anulată';
      default:
        return 'STATUS INVALID!!!'
    }
  }

  getOrders = async (page) => {
    this.setState({loading: true});
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    const response = await axios.default.get(`${baseUrl}v1/admin/orders/${page}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    this.setState({orders: response.data, loading: false, page});
  }

  editInfo = async (key, value, orderId) => {
    this.setState({loading: true});
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    const response = await axios.default.put(`${baseUrl}v1/admin/orders/${orderId}`, {
      [key]: value
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    await this.getOrders(this.state.page);
  }

  formatTime = (timestamp) => {
    let date = this.convertUTCDateToLocalDate(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    return hours + ':' + minutes
  };

  formatDate = (timestamp) => {
    let date = this.convertUTCDateToLocalDate(timestamp);
    return date.toLocaleDateString();
  };

  convertUTCDateToLocalDate(date) {
    const dateLocal = new Date(date);
    return dateLocal//new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
  }

  gotoPage = (page) => {
    if (page > this.state.maxPage) {
      page = this.state.maxPage - 1;
    }
    const canNextPage = page + 1 < this.state.maxPage;
    const canPreviousPage = page - 1 > 0;
    this.setState({canPreviousPage, canNextPage});
    this.getOrders(page);
  }

  previousPage = () => {
    const canPreviousPage = this.state.page - 1 > 0;
    this.setState({canPreviousPage});
    this.getOrders(this.state.page - 1);
  }

  nextPage = () => {
    const canNextPage = this.state.page + 2 < this.state.maxPage;
    this.setState({canPreviousPage: true, canNextPage});
    this.getOrders(this.state.page + 1);
  }

  editFinalizeHour = (order) => {
    const newHour = prompt(`Schimbă ora pentru comanda ${order.id}`, order.status !== 'created' ? this.formatTime(order.restaurant_eta) : '');
    let date = new Date();
    if (order.status !== 'created') {
      date = new Date(order.restaurant_eta)
    }
    if (newHour) {
      const split = newHour.split(':');
      if (split.length !== 2) {
        alert('ora gresita!')
        return;
      }
      if (isNaN(Number(split[0])) || isNaN(Number(split[1]))) {
        alert('ora gresita!')
        return;
      }
      if (Number(split[0]) < 0 || Number(split[0]) > 23 || Number(split[1]) < 0 || Number(split[1]) > 59) {
        alert('ora gresita!')
        return;
      }
      date.setHours(Number(split[0]));
      date.setMinutes(Number(split[1]));
      this.editInfo("restaurant_eta", date, order.id);
    }
  }

  editAddress = (order) => {
    const newAddress = prompt(`Schimbă adresa pentru comanda ${order.id}`, order.address);
    if (!newAddress) {
      return;
    }
    this.editInfo("address_string", newAddress, order.id);
  }

  editPhone = (order) => {
    const newPhone = prompt(`Schimbă adresa pentru comanda ${order.id}`, order.phone);
    if (!newPhone) {
      return;
    }
    this.editInfo("phone", newPhone, order.id);
  }

  deliveryChange = (event, order) => {
    this.editInfo("delivery", event.target.value === "true", order.id);
  }

  statusChanged = (event, order) => {
    this.editInfo("status", event.target.value, order.id);
  }

  render() {
    const {page, maxPage} = this.state;
    return (
      <div className={"container"}>
        <table style={{borderSpacing: 0}}>
          <tr>
            <th className={"row first header"}>Id</th>
            <th className={"row header"}>Restaurant</th>
            <th className={"row header"}>Data</th>
            <th className={"row header"}>Ora</th>
            <th className={"row header"}>Status</th>
            <th className={"row header"}>Finalizare</th>
            <th className={"row header"}>Livrare</th>
            <th className={"row header"}>Plată</th>
            <th className={"row header"}>Livrator</th>
            <th className={"row header"}>Client</th>
            <th className={"row header"}>Adresă</th>
            <th className={"row header"}>Telefon</th>
          </tr>
          {this.state.orders.map((order, i) => {
            return (
              <tr className={order.status}>
                <td className={"row first"}>{order.id}</td>
                <td className={"row"}>{order.restaurant}</td>
                <td className={"row"}>{this.formatDate(order.created)}</td>
                <td className={"row"}>{this.formatTime(order.created)}</td>
                <td
                  className={`row`}>
                  <select className={order.status} value={order.status} onChange={(event) => {
                    this.statusChanged(event, order);
                  }}>
                    <option value={'created'}>Creată</option>
                    <option value={'accepted'}>Acceptată</option>
                    <option value={'prepared'}>Preparată</option>
                    <option value={'picked'}>Ridicată</option>
                    <option value={'delivered'}>Livrată</option>
                    <option value={'canceled'}>Anulată</option>
                  </select>
                </td>
                <td className={"row"}>
                  <div style={{display: 'flex'}}>
                    {order.status !== 'created' ? this.formatTime(order.restaurant_eta) : ''}
                    <button onClick={() => {
                      this.editFinalizeHour(order);
                    }} className={'finalize-button'}>Edit
                    </button>
                  </div>
                </td>
                <td className={"row"}>
                  <select className={order.status} value={order.delivery} onChange={(event) => {
                    this.deliveryChange(event, order)
                  }}>
                    <option value={true}>Livrare</option>
                    <option value={false}>Ridicare</option>
                  </select>
                </td>
                <td className={"row"}>{order.paid ? 'Card Online' : 'Cash'}</td>
                <td className={"row"}>{order.deliverer}</td>
                <td className={"row"}>{order.user}</td>
                <td className={"row"}>
                  <div style={{display: 'flex'}}>
                    {order.delivery ? order.address : 'Ridicare'}
                    {order.delivery && <button onClick={() => {
                      this.editAddress(order);
                    }} className={'finalize-button'}>Edit
                    </button>}
                  </div>
                </td>
                <td className={"row"}>
                  <div style={{display: 'flex'}}>
                    {order.phone}
                    <button onClick={() => {
                      this.editPhone(order);
                    }} className={'finalize-button'}>Edit
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
        <div className="pagination">
          <button onClick={() => this.gotoPage(0)} disabled={!this.state.canPreviousPage}>
            {'<<'}
          </button>
          {' '}
          <button onClick={() => this.previousPage()} disabled={!this.state.canPreviousPage}>
            {'<'}
          </button>
          {' '}
          <button onClick={() => this.nextPage()} disabled={!this.state.canNextPage}>
            {'>'}
          </button>
          {' '}
          <button onClick={() => this.gotoPage(maxPage - 1)} disabled={!this.state.canNextPage}>
            {'>>'}
          </button>
          {' '}
          <span>
          Page{' '}
            <strong>
            {page + 1} of {maxPage}
          </strong>{' '}
        </span>
          <span>
          | Go to page:{' '}
            <input
              type="number"
              defaultValue={page + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                this.gotoPage(page)
              }}
              style={{width: '50px'}}
            />
        </span>{' '}
        </div>
        {this.state.loading &&
        <div className={'loader-container'}>
          <div className={'loader'}/>
        </div>}
      </div>
    )
  }
}