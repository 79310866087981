import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import React from "react";
import * as axios from "axios";

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default class Deliverers extends React.Component {
  state = {
    users: [],
    statistics: []
  };

  componentDidMount() {
    this.getDeliverers();
    this.getStatistics();
  }

  getDeliverers = async () => {
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    const response = await axios.default.get(`${baseUrl}v1/admin/all-deliverers`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    this.setState({users: response.data})
  }

  changeUserStatus = async (event) => {
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const userID = 38;
    await axios.default.put(`${baseUrl}v1/user/admin/${userID}/deliverer/${event.target.name}/change-status`);
    await this.getDeliverers();
  }

  getStatistics = async () => {
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    const response = await axios.default.get(`${baseUrl}v1/admin/statistics`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    let statistics = response.data;
    let total = 0;
    for (let statistic of statistics) {
      total += statistic.ord
    }
    statistics.push({name: "Total", ord: total})
    this.setState({statistics})
  }

  render() {
    const users = this.state.users || [];
    const statistics = this.state.statistics || [];
    return (
      <div>
        <table style={{backgroundColor: 'white', display: 'inline-table', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', borderSpacing: 0}}>
          <tr>
            <th style={{width: 150, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Prenume
            </th>
            <th style={{width: 150, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Nume</th>
            <th style={{width: 50, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Activ</th>
            <th style={{width: 50, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Cash</th>
          </tr>
          {users.map((user, i) => {
            return (
              <tr>
                <td style={{
                  textAlign: 'center',
                  "borderWidth": "1px",
                  'borderColor': "#aaaaaa",
                  'borderStyle': 'solid'
                }}>{user.first_name}</td>
                <td style={{
                  textAlign: 'center',
                  "borderWidth": "1px",
                  'borderColor': "#aaaaaa",
                  'borderStyle': 'solid'
                }}>{user.last_name}</td>
                <td
                  style={{textAlign: 'center', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>
                  <input
                    name={user.id}
                    type="checkbox"
                    checked={user.available}
                    onChange={this.changeUserStatus}/>
                </td>
                <td style={{
                  textAlign: 'center',
                  "borderWidth": "1px",
                  'borderColor': "#aaaaaa",
                  'borderStyle': 'solid'
                }}>{user.cash}</td>
              </tr>
            )
          })}
        </table>
        <div style={{display: 'inline-table', position: 'relative', left: 70}}>
          <table style={{backgroundColor: 'white', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', borderSpacing: 0}}>
            <tr>
              <th style={{width: 30, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Nr</th>
              <th style={{width: 150, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Nume</th>
              <th style={{width: 150, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Comenzi
              </th>
            </tr>
            {statistics.map((st, i) => {
              return (
                <tr>
                  <td style={{
                    textAlign: 'center',
                    "borderWidth": "1px",
                    'borderColor': "#aaaaaa",
                    'borderStyle': 'solid'
                  }}>{i + 1}</td>
                  <td style={{
                    textAlign: 'center',
                    "borderWidth": "1px",
                    'borderColor': "#aaaaaa",
                    'borderStyle': 'solid'
                  }}>{st.name}</td>
                  <td style={{
                    textAlign: 'center',
                    "borderWidth": "1px",
                    'borderColor': "#aaaaaa",
                    'borderStyle': 'solid'
                  }}>{st.ord}</td>
                </tr>
              )
            })}
          </table>
        </div>
      </div>
    )
  }
}