import * as React from "react";
import * as axios from "axios";
import { FullScreen } from "@chiragrupani/fullscreen-react";
import Text from "antd/es/typography/Text";

// const baseUrl = 'http://localhost:8080/';
const baseUrl = 'https://api.appetito.ro/';

class RestaurantView extends React.Component {

  state = {};
  id2List = {};
  interval

  componentDidMount = async () => {
    this.getDeliverers();
    this.interval = setInterval(this.getDeliverers, 5000);
    this.setState({
      darkMode: localStorage.getItem('darkMode') === 'true'
    })
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  getDeliverers = async () => {
    const token = localStorage.getItem('access_token');
    const response = await axios.default.get(`${baseUrl}v1/admin/active-deliverers`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const orders = await this.getOrders();
    let list = {};

    response.data.map((deliverer, index) => {
      list[deliverer.id] = orders
        .filter((order) => {
          order.id = `${order.order_id}`;
          return order.deliverer_id === deliverer.id;
        })
    });

    this.setState({deliverers: response.data, ...list}, () => {
      response.data.map((deliverer, index) => {
        this.id2List[deliverer.id] = deliverer.id;
      });
      this.setState({reload: true});
    });
  };

  getOrders = async () => {
    const token = localStorage.getItem('access_token');
    const response = await axios.default.get(`${baseUrl}v1/admin/orders`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const restaurantName = this.props.match.params.id.replace('_', ' ');
    return response.data.filter(o =>
      o.restaurant_name === restaurantName &&
      ["accepted", "prepared"].includes(o.status));
  };

  getList = id => this.state[this.id2List[id]];

  getTotalCash = (delivererId) => {
    let total = 0
    const orders = this.state[this.id2List[delivererId]];
    for (let order of orders) {
      if (!order.paid) {
        total += order.total_price - order.transport_price
      }
    }
    return total.toFixed(2)
  }

  render() {
    return (
      <FullScreen
        style={{background: "white"}}
        isFullScreen={this.state.isFullScreen}
        onChange={(isFullScreen) => {
          this.setState({ isFullScreen });
        }}>
        <button style={{position: "absolute", right: 0, bottom: 0}} onClick={e => this.setState({isFullScreen: !this.state.isFullScreen})}>Go Fullscreen</button>
      <div style={{display: 'flex'}}>
        {Object.entries(this.id2List).map(([key, value]) => {
          let deliverer = {};
          [deliverer] = this.state.deliverers.filter((deliverer) => {
            return deliverer.id === value
          });
          if (this.getList(key).length === 0) {
            return <></>
          }
          return <>
            <div style={{
              borderRightWidth: "2px",
              borderColor: "black",
              borderRightStyle: "solid",
              borderBottomWidth: "2px",
              borderBottomStyle: "solid"
            }}>
              <div style={{
                fontSize: '30px',
                color: this.state.darkMode ? '#fff' : '#000',
                paddingRight: '20px',
                paddingLeft: '20px',
                borderBottomWidth: "2px",
                borderColor: "black",
                borderBottomStyle: "solid",
                textAlign: "center"
              }}>{`${deliverer.first_name} ${deliverer.last_name}`}
              </div>
              {this.getList(key).map((item, index) => (
                <div style={{ fontSize: '40px', textAlign: "center", color: item.status === "accepted" ? "red" : "green" }}>
                  {item.id % 1000}
                </div>
                ))}
              <div style={{
                textAlign: "center",
                borderTopWidth: "2px",
                borderTopStyle: "solid",
                borderColor: "black",
                padding: 10,
                fontSize: '30px',
              }}>
                <Text>
                  Cash
                </Text>
                <Text style={{fontWeight: "bold", paddingLeft: 7, paddingRight: 7}}>
                  {this.getTotalCash(key)}
                </Text>
                <Text>
                  Lei
                </Text>
              </div>
            </div>
          </>
        })}
      </div>
      </FullScreen>
    )
  }
}

export default RestaurantView