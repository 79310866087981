import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
    background: white;
    width: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 12;
    margin-right: 10px;
    height: 100%;
`;
export const NavLogo = styled(Link)`
  cursor: pointer;
  color: black;
  font-size: 2rem;
  text-decoration: none;
  position: absolute;
  top: 20px;
`;

export const NavLink = styled(Link)`
background-color: white;
margin-top: 25px;
color: #2B478C;
display: flex;
align-items: center;
text-decoration: none;
padding: 10px 20px;
border-radius: 13px;
border-width: 1px;
cursor: pointer;
box-shadow: 0px 5px 10px rgb(43 71 140 / 15%);

&.active {
  color:white;
  background-color: #EB9436;
}
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
`;

export const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 150px;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: transparent;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;