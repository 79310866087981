import React, {useEffect, useState} from "react";
import {
  Nav,
  NavLogo,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import Logo from '../../assets/logo.png';
import * as axios from "axios";
import {Switch} from "@material-ui/core";
import uuid from "uuid";

const getUnconfirmed = async () => {
  const unconfirmedResponse = await axios.default.get(`https://api.appetito.ro/v1/admin/unconfirmed/orders/count`);
  return unconfirmedResponse.data.count;
}

const Navbar = (data) => {
  const [count, setCount] = useState();
  const [isAdmin, setAdmin] = useState();
  const [darkMode, setDarkMode] = useState();
  const [closedForVillages, setClosedForVillages] = useState(false);

  const darkModeChanged = () => {
    localStorage.setItem('darkMode', `${!darkMode}`);
    document.getElementsByTagName('body')[0].style = `background-color: ${!darkMode ? '#000000' : '#ffffff'}`;
    for (let elementsByClassNameElement of document.getElementsByClassName('list-items-style')) {
      elementsByClassNameElement.style = `background-color: ${!darkMode ? '#000000' : '#ffffff'}; padding: 8px; width: 250px; min-width: fit-content;`;
    }
    for (let elementsByClassNameElement of document.getElementsByClassName('list-items-style-title')) {
      elementsByClassNameElement.style = `color: ${darkMode ? '#000000' : '#ffffff'}; font-size: 20px`;
    }
    setDarkMode(!darkMode)
  }

  useEffect(() => {
    const getCount = async () => {
      const unconfirmedResponse = await axios.default.get(`https://api.appetito.ro/v1/admin/unconfirmed/orders/count`);
      setCount(unconfirmedResponse.data.count > 0 ? unconfirmedResponse.data.count : '');
      const isClosedForVillages = await axios.default.get(`https://api.appetito.ro/v1/admin/restaurants/closed-for-village`)
      const isDeliveryAvailable = await axios.default.get(`https://api.appetito.ro/v1/admin/restaurants/delivery-available`)
      setClosedForVillages(isClosedForVillages.data.isClosed || !isDeliveryAvailable.data.available)
    }
    setInterval(getCount, 5000);
    getCount();
    const isAdmin = localStorage.getItem('admin') === 'true';
    const darkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(darkMode);
    setAdmin(isAdmin);
  }, 0);
  const isDarkMode = darkMode === true;
  return (
    <>
      <Nav style={{backgroundColor: isDarkMode ? '#000' : '#fff'}}>
        <NavLogo to="/">
          <img style={{width: 70}} src={Logo} alt={'logo'}/>
        </NavLogo>
        <Bars/>

        <NavMenu>
          <NavLink to="/dashboard" activeStyle>
            Dashboard
          </NavLink>
          <NavLink to="/restaurants" activeStyle>
            Restaurante
            {closedForVillages && <div style={{
              marginLeft: 5,
              backgroundColor: '#EB9436',
              color: '#fff',
              borderRadius: 9,
              width: 18,
              textAlign: 'center'
            }}>
              !
            </div>}
          </NavLink>
          <NavLink to="/orders" activeStyle>
            Comenzi
            <div style={{
              marginLeft: 5,
              backgroundColor: '#EB9436',
              color: '#fff',
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 20
            }}>
              {count}
            </div>
          </NavLink>
          <NavLink to="/deliverers" activeStyle>
            Livratori
          </NavLink>
          <NavLink to="/info-client" activeStyle>
            Info client
          </NavLink>
          {isAdmin && <NavLink to="/statistics" activeStyle>
            Statistici
          </NavLink>}
          {isAdmin && <NavLink to="/notifications" activeStyle>
            Notificări
          </NavLink>}
          {isAdmin && <NavLink to="/invoices" activeStyle>
            Facturi
          </NavLink>}
          {isAdmin && <NavLink to="/credit" activeStyle>
            Creditare
          </NavLink>}
          <div style={{
            alignItems: "center",
            marginTop: 25,
            display: "flex",
            fontSize: 15,
            color: isDarkMode ? 'white' : 'black'
          }}>{isDarkMode ? 'Cu fân gî' : 'Hu șa șî'}
            <Switch
              checked={isDarkMode}
              onChange={darkModeChanged}
            /></div>
          <div style={{
            alignItems: "center",
            marginTop: 25,
            display: "flex",
            fontSize: 15,
            color: isDarkMode ? 'white' : 'black'
          }}>
            v1.0.5
          </div>
        </NavMenu>
      </Nav>
    </>
  );
};
export default Navbar;