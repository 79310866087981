import React, {useEffect} from 'react'
import {Redirect, Link as RouterLink} from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import {actions} from '../../redux/user'
import axios from "axios";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://appetito.ro/">
        Appetito
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white'
  },
}));

export default function SignIn() {
  const isLoggedIn = localStorage.getItem('access_token');

  const classes = useStyles();

  const [values, setValues] = React.useState({
    username: '',
    password: '',
    loginError: false,
  });

  const handleOnChange = (value, name) => {
    setValues({...values, [name]: value, loginError: false})
  };

  const signIn = async () => {
    try {
      const response = await axios.post('https://api.appetito.ro/v1/auth/login/admin', {
        username: values.username,
        password: values.password
      });
      const responseAdmin = await axios.get(`https://api.appetito.ro/v1/admin/super-admin`, {
        headers: {
          Authorization: `Bearer ${response.data.access_token}`
        }
      });
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('admin', responseAdmin.data.isAdmin || false);
      setValues({loginError: false});
    } catch (e) {
      setValues({loginError: true});
    }
  }

  if (isLoggedIn) {
    return <Redirect to="/dashboard" exact/>
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Appetito
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            error={values.loginError}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            helperText={values.loginError ? 'User sau parola incorecte!' : ''}
            autoFocus
            onChange={(e) => handleOnChange(e.target.value, e.target.name)}
          />
          <TextField
            error={values.loginError}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Parolă"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => handleOnChange(e.target.value, e.target.name)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={signIn}
          >
            Sign In
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright/>
      </Box>
    </Container>
  )
}