import * as React from "react";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import * as axios from "axios";

const getItems = (count, offset = 0) =>
  Array.from({length: count}, (v, k) => k).map(k => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`
  }));

// const baseUrl = 'http://localhost:8080/';
const baseUrl = 'https://api.appetito.ro/';
const userID = 38;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, status) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  fontSize: '12px',

  // change background colour if dragging
  background: isDragging ? 'lightyellow' : (status === 'picked') ? 'lightgreen' : (status === 'prepared') ? 'lightblue' : '#EA9336',

  // styles we need to apply on draggables
  maxWidth: 220,
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => {
  const darkMode = localStorage.getItem('darkMode') === 'true'
  return {
    background: isDraggingOver ? 'lightblue' : darkMode ? 'black' : 'white',
    padding: grid,
    width: 250,
    minWidth: 'fit-content',
  }
};

class Orders extends React.Component {

  state = {};
  id2List = {};
  interval

  componentDidMount = async () => {
    this.getDeliverers();
    this.interval = setInterval(this.getDeliverers, 5000);
    this.setState({
      darkMode: localStorage.getItem('darkMode') === 'true'
    })
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  getDeliverers = async () => {
    const token = localStorage.getItem('access_token');
    const response = await axios.default.get(`${baseUrl}v1/admin/active-deliverers`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const orders = await this.getOrders();
    let list = {
      0: orders.filter((order) => {
        order.id = `${order.order_id}`;
        return order.deliverer_id === 0;
      })
    };

    response.data.map((deliverer, index) => {
      list[deliverer.id] = orders
        .filter((order) => {
          order.id = `${order.order_id}`;
          return order.deliverer_id === deliverer.id;
        })
        .sort((a, b) => a.position > b.position ? 1 : -1);
    });

    this.setState({deliverers: response.data, ...list}, () => {
      this.id2List[0] = 0;
      response.data.map((deliverer, index) => {
        this.id2List[deliverer.id] = deliverer.id;
      });
      this.setState({reload: true});
    });
  };

  getOrders = async () => {
    const token = localStorage.getItem('access_token');
    const response = await axios.default.get(`${baseUrl}v1/admin/orders`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const {source, destination, draggableId} = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && destination.droppableId === "0") {
      return;
    }

    if (source.droppableId === destination.droppableId && destination.droppableId !== "0") {
      const result = reorder(this.getList(source.droppableId), source.index, destination.index);
      axios.default.put(`${baseUrl}v1/delivery/orders/${source.droppableId}/reorder`, result.map((order, index) => {
        return {
          order_id: order.order_id,
          position: index
        }
      })).then(() => {
        this.setState({[source.droppableId]: result});
      });
    } else {
      if (destination.droppableId === "0") {
        axios.default.post(`${baseUrl}v1/delivery/orders/${draggableId}/unpick`, {'user_id': `${userID}`});
      } else {
        axios.default.post(`${baseUrl}v1/delivery/orders/${draggableId}/unpick`, {'user_id': `${userID}`}).then(() => {
          axios.default.post(`${baseUrl}v1/delivery/orders/${draggableId}/reserve`, {
            'user_id': `${destination.droppableId}`,
            'sendPush': true
          }).then(() => {
            axios.default.put(`${baseUrl}v1/delivery/orders/${source.droppableId}/reorder`, result[destination.droppableId].map((order, index) => {
              return {
                order_id: order.order_id,
                position: index
              }
            })).then(() => {
              this.setState({[destination.droppableId]: result[destination.droppableId]});
            });
          });
        });
      }

      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({...result});
    }
  };

  displayEta = (item) => {
    const now = parseInt(Date.now() / 1000);
    if (item.restaurant_eta < 0) {
      return 'NECONFIRMATA'
    }
    if ((parseInt(parseInt(item.restaurant_eta) - now)) / 60 > 0) {
      return parseInt((parseInt(parseInt(item.restaurant_eta) - now)) / 60) + ' min'
    }
    return 'Gata'
  };

  formatData = (timestamp) => {
    let date = new Date(timestamp * 1000);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    return hours + ':' + minutes
  };

  render() {
    return (
      <div style={{display: 'flex'}}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          {Object.entries(this.id2List).map(([key, value]) => {
            let deliverer = {};
            if (value === 0) {
              deliverer.first_name = 'Comenzi';
              deliverer.last_name = 'noi';
            } else {
              [deliverer] = this.state.deliverers.filter((deliverer) => {
                return deliverer.id === value
              });
            }
            return <Droppable droppableId={key}>
              {(provided, snapshot) => (
                <div
                  className="list-items-style"
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}>
                  <span className="list-items-style-title" style={{fontSize: '20px', color: this.state.darkMode ? '#fff' : '#000'}}>{`${deliverer.first_name} ${deliverer.last_name}`}</span>
                  {this.getList(key).map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          onClick={() => {
                            let alertMessage = `COMANDA: ${item.id}\n\n`;
                            for (let product of item.products) {
                              alertMessage += `${product.quantity} x ${product.name}\n`;
                              for (let extra of product.extra) {
                                alertMessage += `   - ${extra.name}\n`;
                              }
                              alertMessage += '\n';
                            }
                            alertMessage += `Transport: ${item.transport_price} lei\n`
                            alertMessage += `TOTAL: ${item.total_price} lei\n`;
                            alertMessage += `Modalitate de plata: ${item.paid ? 'Card Online' : 'Cash'}\n\n`;
                            alertMessage += `telefon client: ${item.phone}`;
                            alert(alertMessage)
                          }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            item.status
                          )}>
                          <div><span>Id: {item.id}</span><span style={{
                            marginLeft: '40px',
                            color: this.displayEta(item) === 'NECONFIRMATA' ? 'red' : 'white'
                          }}>
                            {this.displayEta(item)}</span>
                          </div>
                          <div style={{fontWeight: 'bold'}}>Ora: {this.formatData(item.created)}</div>
                          <div style={{fontWeight: 'bold'}}>Fin rest: {this.formatData(item.restaurant_eta)}</div>
                          <div style={{marginBottom: '10px'}}>{item.user_name}</div>
                          <div style={{fontWeight: 'bold'}}>{item.restaurant_name}</div>
                          <div style={{height: '1px', backgroundColor: "black"}}/>
                          <div style={{fontWeight: 'bold'}}>{item.address}</div>
                          <div style={{fontWeight: 'bold', color: 'blue', fontSize: '13px'}}>{item.mentions}</div>
                          {item.voucher !== 0 && <span style={{
                            backgroundColor: '#2b72ff',
                            color: '#fff',
                            paddingLeft: 5,
                            paddingRight: 5,
                            borderRadius: 20,
                            marginTop: 5
                          }}>
                            {item.voucher} lei voucher
                          </span>}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          })}
        </DragDropContext>
      </div>
    )
  }
}

export default Orders