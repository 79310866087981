import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import * as axios from "axios";
import "./styles.css"

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default class InfoClient extends React.Component {
  state = {
    orderId: '',
    addresses: [],
    user: {},
    orders: [],
    loading: false,
  };

  search = async () => {
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    this.setState({loading: true})
    const response = await axios.default.get(`${baseUrl}v1/admin/client-info/${this.state.orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    this.setState({...response.data, loading: false})
  }

  formatDate = (timestamp) => {
    let date = this.convertUTCDateToLocalDate(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    return date.toLocaleDateString() + ` ${hours}:${minutes}`;
  };

  convertUTCDateToLocalDate(date) {
    const dateLocal = new Date(date);
    return dateLocal//new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
  }

  formatAddress(address) {
    return `Str. ${address.street}, 
    ${address.number !== '' ? 'nr. ' + address.number + ', ' : ''}
    ${address.apartment !== '' ? ', ap. ' + address.apartment + ', ' : ''}
    ${address.floor !== '' ? ', etaj ' + address.floor + ', ' : ''}
    ${address.details}, ${address.city}
    `
  }

  render() {
    const orders = this.state.orders || [];
    const addresses = this.state.addresses || [];
    const user = this.state.user || {};
    return (
      <>
        {!this.state.loading && <div className={'container'}>
          <div className={'title'}>
            Id comandă <input
            value={this.state.orderId}
            onChange={(event) => {
              if (event.target.value.length > 7) {
                return
              }
              this.setState({orderId: event.target.value});
            }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            className={'input'}/>
            <button className={'searchButton'}
                    onClick={this.search}>
              Caută
            </button>
          </div>
          {(user.first_name) && <>
            <div style={{fontSize: 30, fontWeight: 800, marginTop: 50}}>{user.first_name + " " + user.last_name}</div>
            <div>{user.phone}</div>
            <div>{user.email}</div>
            <div>{"Data adaugare: " + this.formatDate(user.created)}</div>
            {user.deleted && <div style={{fontSize: 30, fontWeight: 800, color: '#ff0000'}}>{"Dezactivat"}</div>}
          </>
          }
          <div style={{marginTop: 50, fontSize: 30, fontWeight: 800}}>Adrese</div>
          <table style={{
            backgroundColor: 'white',
            "borderWidth": "1px",
            'borderColor': "#aaaaaa",
            'borderStyle': 'solid',
            borderSpacing: 0
          }}>
            <tr>
              <th style={{width: 60, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Id</th>
              <th
                style={{width: 400, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Adresa
              </th>
              <th
                style={{width: 450, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Mentiuni
              </th>
            </tr>
            {addresses.map((address, i) => {
              return (
                <tr>
                  <td style={{
                    textAlign: 'center',
                    "borderWidth": "1px",
                    'borderColor': "#aaaaaa",
                    'borderStyle': 'solid'
                  }}>{address.id}</td>
                  <td style={{
                    textAlign: 'center',
                    "borderWidth": "1px",
                    'borderColor': "#aaaaaa",
                    'borderStyle': 'solid'
                  }}>{this.formatAddress(address)}</td>
                  <td style={{
                    textAlign: 'center',
                    "borderWidth": "1px",
                    'borderColor': "#aaaaaa",
                    'borderStyle': 'solid'
                  }}>{address.description}</td>
                </tr>
              )
            })}
          </table>
          <div style={{fontSize: 30, fontWeight: 800, marginTop: 50}}>Comenzi</div>
          <div style={{display: 'inline-table', position: 'relative', paddingBottom: 80}}>
            <table style={{
              backgroundColor: 'white',
              display: 'inline-table',
              "borderWidth": "1px",
              'borderColor': "#aaaaaa",
              'borderStyle': 'solid',
              borderSpacing: 0
            }}>
              <tr>
                <th style={{width: 60, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Id</th>
                <th style={{width: 130, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Data
                </th>
                <th
                  style={{
                    width: 100,
                    "borderWidth": "1px",
                    'borderColor': "#aaaaaa",
                    'borderStyle': 'solid'
                  }}>Restaurant
                </th>
                <th style={{width: 70, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Id
                  Adresă
                </th>
                <th
                  style={{width: 300, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Mențiuni
                </th>
                <th
                  style={{width: 300, "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>Mențiuni
                  puse de noi
                </th>
              </tr>
              {orders.map((order, i) => {
                return (
                  <tr>
                    <td style={{
                      textAlign: 'center',
                      "borderWidth": "1px",
                      'borderColor': "#aaaaaa",
                      'borderStyle': 'solid'
                    }}>{order.id}</td>
                    <td style={{
                      textAlign: 'center',
                      "borderWidth": "1px",
                      'borderColor': "#aaaaaa",
                      'borderStyle': 'solid'
                    }}>{this.formatDate(order.created)}</td>
                    <td style={{
                      textAlign: 'center',
                      "borderWidth": "1px",
                      'borderColor': "#aaaaaa",
                      'borderStyle': 'solid'
                    }}>{order.restaurant}</td>
                    <td style={{
                      textAlign: 'center',
                      "borderWidth": "1px",
                      'borderColor': "#aaaaaa",
                      'borderStyle': 'solid'
                    }}>{order.addressId}</td>
                    <td style={{
                      textAlign: 'center',
                      "borderWidth": "1px",
                      'borderColor': "#aaaaaa",
                      'borderStyle': 'solid'
                    }}>{order.mentions}</td>
                    <td style={{
                      textAlign: 'center',
                      "borderWidth": "1px",
                      'borderColor': "#aaaaaa",
                      'borderStyle': 'solid'
                    }}>{order.ourMentions}</td>
                  </tr>
                )
              })}
            </table>
          </div>
        </div>}
        {this.state.loading &&
        <div className={'loader-container'}>
          <div className={'loader'}/>
        </div>}
      </>
    )
  }
}