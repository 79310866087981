import React from "react";
import axios from "axios";
import './styles.css';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink, CSVDownload } from "react-csv";

// import {registerLocale} from "react-datepicker";
// import ro from 'date-fns/locale/ro';

// registerLocale('ro', ro)

const baseUrl = 'https://api.appetito.ro/';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default class Invoices extends React.Component {

  state = {
    classes: {},
    rows: [],
    options: [],
    startDate: new Date(),
    endDate: new Date(),
    invoiceDate: new Date(),
    selectedRestaurant: {our_delivery_percentage: 0},
    loading: false,
    totalOrder: 0,
    totalOrderDiscount: 0,
    paidTotal: 0,
    totalOrderPickup: 0,
    oblioCompanies: [],
    csvData: [],
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeInvoiceDate = this.handleChangeInvoiceDate.bind(this);
    const classes = makeStyles({
      table: {
        minWidth: 650,
      },
    });
    this.setState({classes});
    this.getOblioData();
    axios.get(baseUrl + "v1/admin/restaurants").then(res => {
      let options = [];
      const lastRestaurantId = localStorage.getItem('lastRestaurant')
      res.data.forEach(restaurant => {
        if (restaurant.id === +lastRestaurantId) {
          options.push({label: `**********${restaurant.name}**********`, value: restaurant})
        } else {
          options.push({label: restaurant.name, value: restaurant})
        }
      });
      options.push({label: "Toate", value: "Toate"});
      this.setState({options});
    }).catch(() => {
      this.setState({options: []});
    });
  }

  getOblioData = async () => {
    try {
      const companiesResult = await axios.get(baseUrl + 'v1/oblio/companies');
      this.setState({oblioCompanies: companiesResult.data});
    } catch {
      alert('Nu o sa mearga facturile (vezi sa inceapa site-ul cu https)!!!\nVorbeste cu Radu');
    }
  };

  onSelectDropdown = (result) => {
    this.setState({
      selectedRestaurant: result.value
    });
  };

  sanitizeDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();

    return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + ", " + hours + ':' + minutes.substr(-2)
  };

  sanitizeProducts = (products) => {
    let productsString = "";
    products.forEach(product => {
      productsString += product.quantity + " x " + product.name + " " +
        (product.mentions !== "" ? "(" : "") + product.mentions + (product.mentions !== "" ? ") " : "") + "- " +
        (product.quantity * product.price).toFixed(2) + " lei\n";
      if (product.extra.length > 0) {
        product.extra.forEach(e => {
          productsString += "   " + e.name + " - " + (product.quantity * e.price).toFixed(2) + " lei\n"
        })
      }
      if (product.package_fee !== 0) {
        productsString += "   " + product.quantity + " x Ambalaj - " + (product.quantity * product.package_fee).toFixed(2) + " lei\n"
      }
    });
    return productsString;
  };

  grandTotal = (products, status, delivery, paid, user_name) => {
    if (status === 'canceled') {
      return 0
    }
    let total = 0;
    let paidTotal = 0;
    products.forEach(product => {
      total += (product.quantity * product.price);
      total += (product.quantity * product.package_fee);
      if (product.extra.length > 0) {
        product.extra.forEach(e => {
          total += (product.quantity * e.price);
        })
      }
    });
    if (paid) {
      paidTotal += total
    }
    if (status !== 'canceled' && delivery) {
      this.setState({
        totalOrder: (parseFloat(this.state.totalOrder) + parseFloat(total)).toFixed(2),
        paidTotal: (parseFloat(this.state.paidTotal) + parseFloat(paidTotal)).toFixed(2),
      });
    }
    if (status !== 'canceled' && !delivery) {
      this.setState({
        totalOrderPickup: (parseFloat(this.state.totalOrderPickup) + parseFloat(total)).toFixed(2),
        paidTotal: (parseFloat(this.state.paidTotal) + parseFloat(paidTotal)).toFixed(2),
      });
    }
    if ((this.state.selectedRestaurant.id === 101 || this.state.selectedRestaurant.id === 91) && user_name === "Comanda Restaurant") {
      this.setState({
        totalOrderDiscount: (parseFloat(this.state.totalOrderDiscount) + parseFloat(total)).toFixed(2),
      });
    }
  };

  orderTotal = (products, status, delivery) => {
    if (status === 'canceled') {
      return 0
    }
    let total = 0;
    products.forEach(product => {
      total += (product.quantity * product.price);
      total += (product.quantity * product.package_fee);
      if (product.extra.length > 0) {
        product.extra.forEach(e => {
          total += (product.quantity * e.price);
        })
      }
    });
    return total.toFixed(2);
  };

  comisionTotal = () => {
    let total = 0;
    if (this.state.selectedRestaurant.id === 101) {
      total = (((parseFloat(this.state.totalOrder) - parseFloat(this.state.totalOrderDiscount)) * parseFloat(this.state.selectedRestaurant.our_delivery_percentage) / 100) + (parseFloat(this.state.totalOrderDiscount) * 0.22)).toFixed(2);
    } else if (this.state.selectedRestaurant.id === 91) {
      total = (((parseFloat(this.state.totalOrder) - parseFloat(this.state.totalOrderDiscount)) * parseFloat(this.state.selectedRestaurant.our_delivery_percentage) / 100) + (parseFloat(this.state.totalOrderDiscount) * 0.17)).toFixed(2);
    } else {
      total = (parseFloat(this.state.totalOrder) * parseFloat(this.state.selectedRestaurant.our_delivery_percentage) / 100).toFixed(2);
    }
    if (this.state.selectedRestaurant.id === 92 || this.state.selectedRestaurant.id === 94) {
      total = (parseFloat(total) + (parseFloat(this.state.paidTotal) * 0.02)).toFixed(2);
    }
    return total;
  };

  handleSubmit = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({
      loading: true,
      totalOrder: 0,
      totalOrderPickup: 0,
      totalOrderDiscount: 0,
      paidTotal: 0,
    }, () => {
      if (this.state.selectedRestaurant === "Toate") {
        axios.post(baseUrl + "v1/admin/restaurants-orders", {
          start_date: `${this.state.startDate.getDate()}.${this.state.startDate.getMonth() + 1}.${this.state.startDate.getFullYear()}`,
          end_date: `${this.state.endDate.getDate()}.${this.state.endDate.getMonth() + 1}.${this.state.endDate.getFullYear()}`,
          code: 'a9d9aa71f3',
        }).then(res => {
          const csvData = [['Nr.', 'Dată', 'Status', 'Plată', 'Produse', 'Total Comandă']]
          for (const r of res.data) {
            csvData.push([
              r.order_id,
              this.sanitizeDate(r.created),
              r.status === "canceled" ? "Anulat" : r.delivery ? "Livrare" : "Ridicare",
              r.paid ? "Card online" : "Cash",
              this.sanitizeProducts(r.products),
              `${this.orderTotal(r.products, r.status, r.delivery)} lei`
            ])
          }
          this.setState({
            rows: res.data,
            loading: false,
            csvData: csvData,
          });
        }).catch(e => {
          this.setState({
            rows: [],
            loading: false,
            csvData: []
          });
          alert("Parolă greșită!");
        });
      } else {
        axios.post(baseUrl + "v1/admin/restaurant/" + this.state.selectedRestaurant.id + "/orders", {
          start_date: `${this.state.startDate.getDate()}.${this.state.startDate.getMonth() + 1}.${this.state.startDate.getFullYear()}`,
          end_date: `${this.state.endDate.getDate()}.${this.state.endDate.getMonth() + 1}.${this.state.endDate.getFullYear()}`,
          code: 'qwert123',
        }).then(res => {
          const rows = res.data;
          const csvData = [['Nr.', 'Dată', 'Status', 'Plată', 'Produse', 'Total Comandă']]
          for (const row of rows) {
            this.grandTotal(row.products, row.status, row.delivery, row.paid, row.user_name);
            csvData.push([
              row.order_id,
              this.sanitizeDate(row.created),
              row.status === "canceled" ? "Anulat" : row.delivery ? "Livrare" : "Ridicare",
              row.paid ? "Card online" : "Cash",
              this.sanitizeProducts(row.products),
              `${this.orderTotal(row.products, row.status, row.delivery)} lei`
            ])
          }
          const pickup = ((parseFloat(this.state.totalOrderPickup) || 0) * parseFloat(this.state.selectedRestaurant.pickup_percentage) / 100).toFixed(2)
          const delivery = (+this.comisionTotal() || 0).toFixed(2)
          const totalIncome = (+pickup + +delivery || 0).toFixed(2)
          csvData.push(['----------', '----------', '----------', '----------', '----------', '----------'])
          csvData.push([
            'Total Produse Livrare',
            'Comision Appetito Livrare (fără TVA 19%)',
            'Total Produse Ridicare',
            'Comision Appetito Ridicare (fără TVA 19%)',
            'Încasare online',
            'Total Comision Appetito (fără TVA 19%)'
          ])
          csvData.push([
            `${this.state.totalOrder} lei`,
            `${delivery} lei`,
            `${this.state.totalOrderPickup} lei`,
            `${pickup} lei`,
            `${this.state.paidTotal} lei`,
            `${totalIncome} lei`
          ])
          this.setState({
            rows,
            loading: false,
            csvData
          });
        }).catch(e => {
          console.log('error', e)
          this.setState({
            rows: [],
            loading: false,
            csvData: []
          });
          alert("Parolă greșită!");
        });
      }
    });
  };

  handleCreateInvoice = async () => {
    if (this.state.loading) {
      return;
    }
    if (!this.state.selectedRestaurant.id) {
      alert('Alege un restaurant!');
      return;
    }

    // const [company] = this.state.oblioCompanies.filter(comp => {
    //   return comp.cif === this.state.selectedRestaurant.oblio_cif
    // })
    if (!this.state.selectedRestaurant.oblio_cif || this.state.selectedRestaurant.oblio_cif === '') {
      alert('Compania nu are un CIF setat in baza de date!!!')
      return;
    }
    this.setState({
      loading: true,
    });
    const now = this.state.invoiceDate;
    let nowString = now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2);
    const due = new Date(this.state.invoiceDate.valueOf());
    due.setDate(due.getDate() + 15);
    const dueString = due.getFullYear() + "-" + ("0" + (due.getMonth() + 1)).slice(-2) + "-" + ("0" + due.getDate()).slice(-2);
    let comisionTotal = 0;
    let comisionDiscount = 0.22; // LA ROMA AVEM 22% PENTRU COMENZILE PUSE DE EI
    if (this.state.selectedRestaurant.id === 91) {
      comisionDiscount = 0.17; // LA CALIPSO AVEM 17% PENTRU COMENZILE PUSE DE EI
    }
    if (this.state.selectedRestaurant.id === 101 || this.state.selectedRestaurant.id === 91) {
      comisionTotal = (((parseFloat(this.state.totalOrder) - parseFloat(this.state.totalOrderDiscount)) * parseFloat(this.state.selectedRestaurant.our_delivery_percentage) / 100) + (parseFloat(this.state.totalOrderPickup) * parseFloat(this.state.selectedRestaurant.pickup_percentage) / 100) + (parseFloat(this.state.totalOrderDiscount) * comisionDiscount)).toFixed(2);
    } else {
      comisionTotal = ((parseFloat(this.state.totalOrder) * parseFloat(this.state.selectedRestaurant.our_delivery_percentage) / 100) + (parseFloat(this.state.totalOrderPickup) * parseFloat(this.state.selectedRestaurant.pickup_percentage) / 100)).toFixed(2);
    }
    if (this.state.selectedRestaurant.id === 92 || this.state.selectedRestaurant.id === 94) {
      comisionTotal = parseFloat(comisionTotal) + (parseFloat(this.state.paidTotal) * 0.02);
    }
    const startDate = `${this.state.startDate.getDate()}.${this.state.startDate.getMonth() + 1}.${this.state.startDate.getFullYear()}`
    const endDate = `${this.state.endDate.getDate()}.${this.state.endDate.getMonth() + 1}.${this.state.endDate.getFullYear()}`
    const comision = parseFloat(Math.round(comisionTotal * 119) / 100).toFixed(2)
    const adminFee = parseFloat(Math.round(this.state.selectedRestaurant.admin_fee * 59.5) / 100).toFixed(2)
    let products = [{
      name: "Comision Livrare + Ridicare",
      description: `Aferent perioadei ${startDate}-${endDate}`,
      price: comision,
      vatIncluded: 1,
    }]
    if (this.state.selectedRestaurant.admin_fee > 0) {
      products.push({
        name: "Taxa de administrare",
        price: adminFee,
        vatIncluded: 1,
      })
    }
    const total = parseFloat(`${+comision + +adminFee}`).toFixed(2)
    let mentions = ''
    if (this.state.paidTotal > 0) {
      mentions = `Încasări online: ${this.state.paidTotal} RON\nTotal Factura: -${total} RON\n
      ----------------------------------------------------------------------------------------------------\n
      Sold de achitat de catre Appetito / partener (pozitiv / negativ): ${parseFloat(`${+this.state.paidTotal - +total}`).toFixed(2)} RON`
    }
    const invoiceResult = await axios.post(baseUrl + 'v1/oblio/invoice', {
      cif: "RO42635319",
      issueDate: nowString,
      dueDate: dueString,
      seriesName: this.state.selectedRestaurant.city === "Mediaș" ? "MED" : "AP",
      language: "RO",
      precision: 2,
      currency: "RON",
      client: {
        cif: this.state.selectedRestaurant.oblio_cif
      },
      issuerName: "Razvan Maior",
      internalNote: "Factura emisa din API",
      workStation: "Sediu",
      products,
      mentions: mentions
    });
    if (this.state.paidTotal > 0) {
      const params = new URLSearchParams();
      params.append('cif', "RO42635319")
      params.append('seriesName', invoiceResult.data.seriesName)
      params.append('number', invoiceResult.data.number)
      params.append('collect[type]', "Alta incasare banca")
      params.append('collect[documentNumber]', "Incasare online in numele clientului")
      params.append('collect[value]', this.state.paidTotal)
      await axios.put(baseUrl + 'v1/oblio/invoice/payment', params)
    }
    localStorage.setItem('lastRestaurant', this.state.selectedRestaurant.id)
    const downloadLink = document.createElement("a");
    const url = new URL(invoiceResult.data.link);
    downloadLink.href = `https://oblio.eu/docs/download/invoice/${url.searchParams.get('id')}`;
    downloadLink.download = `${this.state.selectedRestaurant.name}-factura.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    this.setState({
      loading: false,
    });
  };

  handleChangeInvoiceDate(event) {
    this.setState({invoiceDate: event.target.value});
  }

  render() {
    const {classes, options, rows, selectedRestaurant, paidTotal} = this.state;
    const startDate = `${this.state.startDate.getDate()}.${this.state.startDate.getMonth() + 1}.${this.state.startDate.getFullYear()}`
    const endDate = `${this.state.endDate.getDate()}.${this.state.endDate.getMonth() + 1}.${this.state.endDate.getFullYear()}`

    return (
      <div className="App">
        <Dropdown className="Dropdown" options={options} onChange={this.onSelectDropdown.bind(this)}
                  placeholder="Alege un restaurant"/>
          <div style={{width: '100%', display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 50}}>
            <div style={{marginTop: 10, display: "flex", justifyContent: "space-between", width: 300}}>
            <span>
              Start Date
            </span>
              <DatePicker dateFormat="dd/MM/yyyy" locale="ro" selected={this.state.startDate || Date.now()}
                          onChange={(date) => this.setState({startDate: date})}/>
            </div>
            <div style={{marginTop: 10, display: "flex", justifyContent: "space-between", width: 300}}>
            <span>
              End Date
            </span>
              <DatePicker dateFormat="dd/MM/yyyy" locale="ro" selected={this.state.endDate || Date.now()}
                          onChange={(date) => this.setState({endDate: date})}/>
            </div>
            <div style={{marginTop: 10, display: "flex", justifyContent: "space-between", width: 300}}>
            <span>
            Data Factura
            </span>
              <DatePicker dateFormat="dd/MM/yyyy" locale="ro" selected={this.state.invoiceDate}
                          onChange={(date) => this.setState({invoiceDate: date})}/>
            </div>
            <div style={{marginTop: 10}}>
              <button style={{cursor: "pointer"}} onClick={this.handleSubmit}>Search</button>
              <button style={{marginLeft: 20, cursor: "pointer"}} onClick={this.handleCreateInvoice}>Creaza Factura</button>
              <button style={{marginLeft: 20}}><CSVLink style={{color: "black", textDecoration: "none"}} filename={`${this.state.selectedRestaurant.name}(${startDate}-${endDate}).csv`} data={this.state.csvData}>Descarcă CSV</CSVLink></button>
            </div>
          </div>
          <div id='divToPrint'>
            <header className="App-header">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Nr.</StyledTableCell>
                      <StyledTableCell>Data</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Plată</StyledTableCell>
                      <StyledTableCell>Produse</StyledTableCell>
                      <StyledTableCell>Total comanda (cu TVA 19%)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow style={{
                        backgroundColor: row.status === "canceled" && "#FF0000"
                      }} key={row.order_id}>
                        <TableCell component="th" scope="row">
                          {row.order_id}
                        </TableCell>
                        <TableCell>{this.sanitizeDate(row.created)}</TableCell>
                        <TableCell>{row.status === "canceled" ? "Anulat" : row.delivery ? "Livrare" : "Ridicare"}</TableCell>
                        <TableCell>{row.paid ? "Card online" : "Cash"}</TableCell>
                        <TableCell style={{
                          whiteSpace: "break-spaces",
                          wordWrap: "break-word",
                          maxWidth: "300px",
                        }}>{this.sanitizeProducts(row.products)}</TableCell>
                        <TableCell>{this.orderTotal(row.products, row.status, row.delivery)} lei</TableCell>
                      </StyledTableRow>
                    ))}
                    <TableRow style={{
                      backgroundColor: "#EB9436"
                    }}>
                      <TableCell rowSpan={1}/>
                      <TableCell colSpan={4}>Total Livrare</TableCell>
                      <TableCell>{this.state.totalOrder} lei</TableCell>
                    </TableRow>
                    <TableRow style={{
                      backgroundColor: "#EB9436"
                    }}>
                      <TableCell rowSpan={1}/>
                      <TableCell colSpan={4}>Comision Appetito Livrare (fara TVA 19%)</TableCell>
                      <TableCell>{this.comisionTotal()} lei</TableCell>
                    </TableRow>
                    <TableRow style={{
                      backgroundColor: "#EB9436"
                    }}>
                      <TableCell rowSpan={1}/>
                      <TableCell colSpan={4}>Total Ridicare</TableCell>
                      <TableCell>{this.state.totalOrderPickup} lei</TableCell>
                    </TableRow>
                    <TableRow style={{
                      backgroundColor: "#EB9436"
                    }}>
                      <TableCell rowSpan={1}/>
                      <TableCell colSpan={4}>Comision Appetito Ridicare (fara TVA 19%)</TableCell>
                      <TableCell>{(parseFloat(this.state.totalOrderPickup || '0') * parseFloat(selectedRestaurant.pickup_percentage || '0') / 100).toFixed(2)} lei</TableCell>
                    </TableRow>
                    <TableRow style={{
                      backgroundColor: "#EB9436"
                    }}>
                      <TableCell rowSpan={1}/>
                      <TableCell colSpan={4}>Incasare online</TableCell>
                      <TableCell>{paidTotal} lei</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </header>
          </div>
        {this.state.loading &&
        <div className={'loader-container'}>
          <div className={'loader'}/>
        </div>}
      </div>
    );
  }
}