import React from "react";
import "./styles.css"
import axios from "axios";

export default class Credit extends React.Component {
  state = {
    orderNumber: '',
    amount: '',
    loading: false
  }

  openPayment = async () => {
    // const bodyFormData = new FormData();
    // bodyFormData.append('data', 'cBcpDs/blBsdbU7NWK0OXTWwSwpxQ+O/NQb+gddSxuUDgalVP3JKjl9yhP/pltZCaoIUTmUo+bm/Kjr2Uo1xP+E81Gm+pzxGuAMmjVdvZQXGxzvN2AuB31Yn08W5QgfM4oDQ26mSJ57cL/UvKDOAYhBrngsylHPxOf+OEHzhlqvrvnojdcuWNOKGkgvp4dVx4IYUQVE5KLBBR1L4M3WWZpYI58FqF2Yl/36Bsf4mSCYPoVYfxj/g6YkOLxjM36qDVumcKtOKz10gTSP3YGlppkdR1siIi7BBEdwz5Q1h5/iAJEa/0ihiVy7jyeoqelN/qbO+M3tG4rDUPef5aqrjc5bZRArIypy9vzdsV1zLiXzIQjVAdUhR90iWHm+7/HBv8ZYyaUk6+7zsqJMcoH2aIYscjaO3rgxI5Zjj20U6E9T5BmWZ/F9/b3c7Z9UWgrUIs+oeIl17rkVjgTu5Ze7x3Q73C600w9IPe9jIQ1PJlPdKaOx1z/DYcY1BI0JgFr4QDwiO/mzdltc6tDc6v9nDOVvEFn4KXLg3dXQrDVvu200HBNFg1OmFnkPvWSWu1GE4sKXKr0bkhY3TTSf0HrhUOWRKEY0L1WHNCIs1jxy60SeKJ2AoVgdgckOO25U/PsXNGogpCjJpiZWA+7PFMdT0JtJnFdrryQ2qqK5nUSBMrY533LTdOhAXSLpVQHD17gIUUQFBnV+PKu1J47FxcIyswg5h8Hwc3XeX+xvhhigl5hvkH/JxCKEF6h9gycC/nMj3E4xrQuxxfHvUPgW0h0GX7ruFrU2El0ZDtZ4SOQAh5VCNT4iFxHmNeijLhrPzJJR3Vl8/sYtPQ2gCLxUSIDortDfDXd9sEBm/GJUQzYgkPzNSZtWnKmyURmUmTK1jpA8DGYTCe6oDRUN7SLWwqvYkau6Ipw3K5IbfWtrGkSaHaYJ4x24KawGTUSBp2/hS21JObt4JCAQi2lmVnYthSq6rSuOz5A56D401yNjx6XdpXPVz4yN+MD1rV6RsbMZdlbFOAkayeHkBKq8W3MtrCT8nQ7utbshbr/vV+NjQGooLflXwg64RoxLMvoxPo9fzI/InXGOvB2w9jHCervDML7eaXkAzUN5z3kR55U8FNKR5fLQIfqfQpyYLr0wfWvv5hfG/52BmNKic+RDsdm0kNQKmncfJYxWZKRbM');
    // bodyFormData.append('env_key', 'Jad2H0QBb4rPWmcATgVMqu1UNKi0Gy5dN0nchkR71hk2sSUzxuqbQge+a4fJ4mHcbw5FXLJezaE3kPndYZyrFI4Mwpxukb9FGxn3zXhETLdB2RXVgJHB62DjIo2jbqizEIx5GiaJe16qHrLTQ0x/iL5tj5/VScCg/DkREmT8NXA=');
    // const config = {
    //   headers: {"Content-Type": "multipart/form-data"}
    // };
    // const response = await axios.post('https://secure.mobilpay.ro/', bodyFormData, config)
    // const paymentWindow = window.open('https://secure.mobilpay.ro/', '_blank')
    // paymentWindow.document.write(response.data)
  }

  render() {
    return (
      <div className={'container'}>
        <form action="https://secure.mobilpay.ro" method="post" target="_blank">
          <input type="hidden" name="data" value="Hix9L6NsWnoWNtiNCTjG7JcvvXyRk4kNc959WmK4ldUGyEc1nqzU1Io29PUcr39d4ywQY2XoCH9lVzsTCJRPTxxd9qzTKHP3Ki8GJRQh50FmmAzComQ2rog6JOyObMR7StrXOn6FV3dGE06/YGTSRdKfG+6HDWTPmis0CFF1lW3KTos8cwvtHZUvDfhh6WvD6AmCz3RhAQ1/gy7Vl0W32Q9nLc6ncoH0Zt6hiYYX2P2ymQkNlpRaqlFmpjuvMUOz8od4iVlagGzD53ouCmAhWbvfXcPG/nlwefPuUBvnPox9vL3RcZhwbjh30ydDrjl4aSpoXIckzYoCav+1UdGRve8tIQbdjQcoqeh02jnXfK27S1NxVZmjX/lfHVR9E4sUhwJ12go2tdNo9Ye+O/R2I01SxwAs+r8FxzzXT4PXP/bMC30g7z4k1Ljnx2UwbUL+rgYnOZa9XEG1fA13xlQNQGahg/oHjIlk9Qp+m1cunuoeC0dDzoNuSaA6R0BvdrRtkLxcM9XnbMI6MnKIxe2LVi4qojWvFgRo2PZ/oIBs2JpL+42WBweUrBIUTrM6rGQ8Rm4KWYzA6tb3Nc9Cr1gKbIJqIe7uyVOAS8TBOhdB5qT4bRjlofLJxM1qHUG19I3YNNErPRm7Ju76MmTKFZGliLhnA+popB9hcvC09+YT8p0AE8OaZzyUjlZP56RQBJ94aV6/ssap+o3TZFg9V/LWp/xTieU1IWqNfBRKe86cEgG/zBkoCk6WPVyfFECFE4C154EzVdQGJsKfYnNJaZjc/mE7xZprmjiAgyBPgnCwTSSXm53ldzjBoMDNEMSvzbR3gGhVINyjLzqI/NFrvYzMqksPQeKN1iRU35glYrF7MAEfHKvyXonTZOQ66OZw5+Z536rSqFKxeamVACV1S+hPimU45engm11XsYqDE3cIPQAgc5b9VjKdovN/hw==" />
          <input type="hidden" name="env_key" value="k4gx3o24AN0SN624Rs6ZlP5fyT0GIo6xgIqSuO+SMJtg+BfRtRN8BY+1k02xdg3oOGGDmP7bC3NUcn05lazYhdDKT4dl6KaY5LRvGitrLoKBjsLupJ0s9Z0FlNgwet8FHFHfRwJarAVwqdyP3YOdTWiPgUQxMSKOWM3kiUYyND4=" />
          <div className={'title'}>
            Număr Comandă <input
            value={this.state.orderNumber}
            onChange={(event) => {
              this.setState({orderNumber: event.target.value});
            }}
            className={'input'}/>
          </div>
          <div className={'message'}>
            Valoare Restituită <input
            value={this.state.amount}
            onChange={(event) => {
              this.setState({amount: event.target.value});
            }}
            className={'input'}/>
          </div>
          <button className={'sendButton'}
                  type="submit"
            // onClick={this.openPayment}
          >
            Trimite
          </button>
        </form>
        {this.state.loading &&
        <div className={'loader-container'}>
          <div className={'loader'}/>
        </div>}
      </div>
    )
  }
}