import React from 'react'
import {useSelector} from 'react-redux'

import {makeStyles} from '@material-ui/core/styles'
import Orders from "../../Components/Orders";

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function Public() {
  const classes = useStyles();
  const counter = useSelector(state => state.counter);

  return (
    <Orders/>
  )
}