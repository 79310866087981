import React from "react";
import * as axios from "axios";
import {Switch} from "@material-ui/core";

export default class Restaurants extends React.Component {
  state = {
    restaurants: [],
    loading: false,
    isVillagesClosed: true,
    isDeliveryAvailable: true,
  };

  componentDidMount() {
    this.getRestaurants();
    this.getAvailableVillage();
  }

  componentWillUnmount() {
  }

  async getRestaurants() {
    this.setState({loading: true});
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    const response = await axios.default.get(`${baseUrl}v1/admin/restaurants`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    this.setState({restaurants: response.data, loading: false});
  }

  async getAvailableVillage() {
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    const response = await axios.default.get(`${baseUrl}v1/admin/restaurants/closed-for-village`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const response2 = await axios.default.get(`${baseUrl}v1/admin/restaurants/delivery-available`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    this.setState({
      isVillagesClosed: response?.data?.isClosed ?? false,
      isDeliveryAvailable: response2?.data?.available ?? true,
      loading: false
    });
  }

  async switchAvailableVillage() {
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    const response = await axios.default.post(`${baseUrl}v1/admin/restaurants/closed-for-village`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    this.setState({isVillagesClosed: response?.data?.isClosed ?? false, loading: false});
  }

  async switchDeliveryAvailable() {
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    const response = await axios.default.post(`${baseUrl}v1/admin/restaurants/delivery-available`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    this.setState({isDeliveryAvailable: response?.data?.available ?? true, loading: false});
  }

  async updateRestaurantStatus(restaurantId, status) {
    this.setState({loading: true});
    // const baseUrl = 'http://localhost:8080/';
    const baseUrl = 'https://api.appetito.ro/';
    const token = localStorage.getItem('access_token');
    await axios.default.put(`${baseUrl}v1/admin/restaurant/${restaurantId}`, {available: status}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  render() {
    return (
      <div className={"container"}>
        <table style={{borderSpacing: 0}}>
          <tbody>
          <tr>
            <th className={"row first header"}>Restaurant</th>
            <th className={"row header"}>Disponibil</th>
            <th className={"row header"}>Orar azi</th>
            <th className={"row header"}>Telefon</th>
          </tr>
          <tr style={{background: '#f7d292'}}>
            <td className={"row first"}>Disponibile toate restaurantele</td>
            <td className={"row"}><Switch checked={this.state.isDeliveryAvailable} onChange={async () => {
              this.setState({isDeliveryAvailable: !this.state.isDeliveryAvailable})
              await this.switchDeliveryAvailable()
            }}/></td>
            <td className={"row"}/>
            <td className={"row"}/>
          </tr>
          <tr style={{background: '#f7d292'}}>
            <td className={"row first"}>Satele din Turda si Câmpia</td>
            <td className={"row"}><Switch checked={!this.state.isVillagesClosed} onChange={async () => {
              this.setState({isVillagesClosed: !this.state.isVillagesClosed})
              await this.switchAvailableVillage()
            }}/></td>
            <td className={"row"}/>
            <td className={"row"}/>
          </tr>
          {this.state.restaurants.map((restaurant, i) => {
            return (
              <tr>
                <td className={"row first"}>{restaurant.name} {restaurant.city}</td>
                <td className={"row"}><Switch checked={restaurant.available} onChange={async () => {
                  await this.updateRestaurantStatus(restaurant.id, !restaurant.available)
                  await this.getRestaurants();
                }}/></td>
                <td className={"row"}>{restaurant.open_hours === '00:00-00:00' ? 'Închis' : restaurant.open_hours}</td>
                <td className={"row"}>{restaurant.phone}</td>
              </tr>
            )
          })}
          </tbody>
        </table>
        {this.state.loading &&
        <div className={'loader-container'}>
          <div className={'loader'}/>
        </div>}
      </div>
    )
  }
}