import React from "react";
import "./styles.css"
import axios from "axios";

export default class Notifications extends React.Component {
  state = {
    cities: [],
    selectedCity: 'Turda',
    title: '',
    message: '',
    loading: false
  }

  constructor() {
    super();
    this.getCities();
  }

  getCities = async () => {
    this.setState({loading: true})
    const response = await axios.get('https://api.appetito.ro/v1/city');
    this.setState({cities: response.data, loading: false});
  }

  sendNotification = async () => {
    const {title, message, selectedCity} = this.state;
    this.setState({loading: true})
    const token = localStorage.getItem('access_token');
    const response = await axios.post('https://api.appetito.ro/v1/admin/push-notification', {
      title, message, city: selectedCity
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    this.setState({loading: false})
    alert(`Trimis către ${response.data.devices} dispozitive`);
  }

  render() {
    return (
      <div className={'container'}>
        <div className={'title'}>
          Titlu <input
          value={this.state.title}
          onChange={(event) => {
            this.setState({title: event.target.value});
          }}
          className={'input'}/>
        </div>
        <div className={'message'}>
          Mesaj <textarea
          value={this.state.message}
          onChange={(event) => {
            this.setState({message: event.target.value});
          }}
          className={'textarea'}/>
        </div>
        <div className={'city'}>
          Oraș
          <select className={'selector'} onChange={(event) => {
            this.setState({selectedCity: event.target.value});
          }}>
            {this.state.cities.map((city) => <option value={city.name}>{city.name}</option>)}
          </select>
        </div>
        <button className={'sendButton'}
                onClick={this.sendNotification}>
          Trimite
        </button>
        {this.state.loading &&
        <div className={'loader-container'}>
          <div className={'loader'}/>
        </div>}
      </div>
    )
  }
}