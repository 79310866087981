import React from "react";
import "./styles.css"
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import * as axios from "axios";
import moment from "moment";
import {Switch} from "@material-ui/core";

export default class Statistics extends React.Component {
  state = {
    totals: []
  }

  constructor() {
    super();
    this.state = {
      loading: true,
      series: [],
      ordersSeries: [],
      totals: [],
      width: 0,
      height: 0,
      options: {
        chart: {
          id: 'totals',
          type: 'line',
          height: 350,
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          min: new Date('18 Aug 2020').getTime(),
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        },
        colors: ['#EB9436', '#2B9BEC', '#23B80B', '#CD0B51', '#BA34EA', '#6B5F4C'],
      },
      ordersOptions: {
        chart: {
          id: 'orders',
          type: 'line',
          height: 350,
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          min: new Date('18 Aug 2020').getTime(),
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: 'ddd, dd.MMM.yyyy'
          }
        },
        colors: ['#EB9436', '#2B9BEC', '#23B80B', '#CD0B51', '#BA34EA', '#6B5F4C'],
      },
      selection: 'one_year',
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.getTotals();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  getTotals = async () => {
    this.setState({loading: true});
    const token = localStorage.getItem('access_token');
    const width = this.state.width || window.innerWidth;
    const totalsUrl = width > 500 ? 'https://api.appetito.ro/v1/admin/totals-by-date' : 'https://api.appetito.ro/v1/admin/totals-by-date/last-two-weeks';
    const response = await axios.default.get(totalsUrl, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const allTotals = [];
    const totals = [];
    const totalsTurda = [];
    const totalsCampia = [];
    const totalsDej = [];
    const totalsMedias = [];
    const totalsOthers = [];
    const ordersCount = [];
    const ordersCountTurda = [];
    const ordersCountCampia = [];
    const ordersCountDej = [];
    const ordersCountMedias = [];
    const ordersCountOthers = [];
    for (let total of response.data) {
      totals.push([total.timestamp * 1000, parseFloat(total.total).toFixed(2)])
      totalsTurda.push([total.timestamp * 1000, parseFloat(total.total_turda).toFixed(2)])
      totalsCampia.push([total.timestamp * 1000, parseFloat(total.total_campia).toFixed(2)])
      totalsDej.push([total.timestamp * 1000, parseFloat(total.total_dej).toFixed(2)])
      totalsMedias.push([total.timestamp * 1000, parseFloat(total.total_medias).toFixed(2)])
      totalsOthers.push([total.timestamp * 1000, parseFloat(total.total_others).toFixed(2)])
      ordersCount.push([total.timestamp * 1000, total.count])
      ordersCountTurda.push([total.timestamp * 1000, total.count_turda])
      ordersCountCampia.push([total.timestamp * 1000, total.count_campia])
      ordersCountDej.push([total.timestamp * 1000, total.count_dej])
      ordersCountMedias.push([total.timestamp * 1000, total.count_medias])
      ordersCountOthers.push([total.timestamp * 1000, total.count_others])

      allTotals.push({
        date: total.timestamp * 1000,
        total: parseFloat(total.total).toFixed(2),
        count: total.count
      })
    }
    this.setState({
      loading: false,
      totals: allTotals,
      series: [
        {name: 'Total', data: totals},
        {name: 'Turda', data: totalsTurda},
        {name: 'Câmpia Turzii', data: totalsCampia},
        {name: 'Dej', data: totalsDej},
        {name: 'Mediaș', data: totalsMedias},
        {name: 'Altele', data: totalsOthers},
      ],
      ordersSeries: [
        {name: 'Total', data: ordersCount},
        {name: 'Turda', data: ordersCountTurda},
        {name: 'Câmpia Turzii', data: ordersCountCampia},
        {name: 'Dej', data: ordersCountDej},
        {name: 'Mediaș', data: ordersCountMedias},
        {name: 'Altele', data: ordersCountOthers},
      ]
    });
  }

  updateData(timeline, chartId) {
    this.setState({
      selection: timeline
    })

    const now = moment().unix() * 1000;
    const lastMonth = moment().subtract(1, 'months').unix() * 1000
    const sixMonthsAgo = moment().subtract(6, 'months').unix() * 1000
    const lastYear = moment().subtract(1, 'year').unix() * 1000
    const startOfYear = moment().startOf('year').unix() * 1000
    const startDate = new Date('18 Aug 2020').getTime();
    switch (timeline) {
      case 'one_month':
        ApexCharts.exec(
          chartId,
          'zoomX',
          lastMonth,
          now
        )
        break
      case 'six_months':
        ApexCharts.exec(
          chartId,
          'zoomX',
          sixMonthsAgo,
          now
        )
        break
      case 'one_year':
        ApexCharts.exec(
          chartId,
          'zoomX',
          lastYear,
          now
        )
        break
      case 'ytd':
        ApexCharts.exec(
          chartId,
          'zoomX',
          startOfYear,
          now
        )
        break
      case 'all':
        ApexCharts.exec(
          chartId,
          'zoomX',
          startDate,
          now
        )
        break
      default:
    }
  }

  parseDate(timestamp) {
    const date = new Date(timestamp);
    const days = ['Duminica','Luni','Marti','Miercuri','Joi','Vineri','Sambata'];
    return `${days[date.getDay()]}, ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={'loader-container'}>
          <div className={'loader'}/>
        </div>
      )
    }
    if (this.state.width <= 500) {
      return (
        <div>
          <div className={"container"} style={{marginTop: 60}}>
            <table style={{borderSpacing: 0}}>
              <tbody>
              <tr>
                <th className={"row first header"}>Data</th>
                <th className={"row header comision"}>Comision</th>
                <th className={"row header"}>Nr.</th>
              </tr>
              {this.state.totals.map((totalRow, i) => {
                console.log(totalRow)
                return (
                  <tr>
                    <td className={"row first"}>{this.parseDate(totalRow.date)}</td>
                    <td className={"row"}>{totalRow.total} lei</td>
                    <td className={"row"}>{totalRow.count}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
    return (
      <div className="container">
        <div style={{fontSize: 40, fontWeight: 'Bold', color: '#EB9436'}}>Comision</div>
        <div style={{width: '100%'}} id="chart">
          <div className="toolbar">
            <button id="one_month"

                    onClick={() => this.updateData('one_month', 'totals')}
                    className={(this.state.selection === 'one_month' ? 'active' : '')}>
              1M
            </button>
            &nbsp;
            <button id="six_months"

                    onClick={() => this.updateData('six_months', 'totals')}
                    className={(this.state.selection === 'six_months' ? 'active' : '')}>
              6M
            </button>
            &nbsp;
            <button id="one_year"


                    onClick={() => this.updateData('one_year', 'totals')}
                    className={(this.state.selection === 'one_year' ? 'active' : '')}>
              1Y
            </button>
            &nbsp;
            <button id="ytd"

                    onClick={() => this.updateData('ytd', 'totals')}
                    className={(this.state.selection === 'ytd' ? 'active' : '')}>
              YTD
            </button>
            &nbsp;
            <button id="all"

                    onClick={() => this.updateData('all', 'totals')}
                    className={(this.state.selection === 'all' ? 'active' : '')}>
              ALL
            </button>
          </div>

          <div id="chart-timeline">
            <Chart options={this.state.options} series={this.state.series} type="line" height={350}/>
          </div>
        </div>

        <div style={{fontSize: 40, fontWeight: 'Bold', color: '#EB9436'}}>Comenzi</div>

        <div style={{width: '100%'}} id="chart">
          <div className="toolbar">
            <button id="one_month"

                    onClick={() => this.updateData('one_month', 'orders')}
                    className={(this.state.selection === 'one_month' ? 'active' : '')}>
              1M
            </button>
            &nbsp;
            <button id="six_months"

                    onClick={() => this.updateData('six_months', 'orders')}
                    className={(this.state.selection === 'six_months' ? 'active' : '')}>
              6M
            </button>
            &nbsp;
            <button id="one_year"


                    onClick={() => this.updateData('one_year', 'orders')}
                    className={(this.state.selection === 'one_year' ? 'active' : '')}>
              1Y
            </button>
            &nbsp;
            <button id="ytd"

                    onClick={() => this.updateData('ytd', 'orders')}
                    className={(this.state.selection === 'ytd' ? 'active' : '')}>
              YTD
            </button>
            &nbsp;
            <button id="all"

                    onClick={() => this.updateData('all', 'orders')}
                    className={(this.state.selection === 'all' ? 'active' : '')}>
              ALL
            </button>
          </div>

          <div id="chart-timeline">
            <Chart options={this.state.ordersOptions} series={this.state.ordersSeries} type="line" height={350}/>
          </div>
        </div>
      </div>
    )
  }
}