import React from 'react'
import {Route, Switch, Redirect, BrowserRouter as Router} from 'react-router-dom'
import SignIn from './SignIn/SignIn'
import Public from './PublicPage/Public'
import NotFound from './NotFound/NotFound'
import Deliverers from "./Deliverers/Deliverers";
import Navbar from "../Components/Navbar";
import Orders from "../Containers/Orders"
import Notifications from "./Notifications";
import Statistics from "./Statistics";
import Restaurants from "./Restaurants";
import InfoClient from "./InfoClient/InfoClient";
import Invoices from "./Invoices";
import Credit from "./Credit";
import RestaurantView from "./RestaurantView/RestaurantView";

export default function App() {
  const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => {
      const isLoggedIn = localStorage.getItem('access_token');
      return (
        isLoggedIn
          ? <Component {...props} />
          : <Redirect to='/signin'/>
      )
    }}/>
  );

  const darkMode = localStorage.getItem('darkMode') === 'true';
  if (darkMode) {
    document.getElementsByTagName('body')[0].style = 'background-color: #000000';
  }

  return (
    <div style={{display: 'flex'}}>
      <Router>
        <Switch>
          <Route path="/signin" component={SignIn}/>
          <PrivateRoute path='/restaurant-view/:id' component={RestaurantView} exact/>
          <div style={{display: 'flex', height: '98vh', flex: 1}}>
            <Navbar/>
            <PrivateRoute path='/' component={() => (<Redirect to='/dashboard'/>)} exact/>
            <PrivateRoute path='/dashboard' component={Public} exact/>
            <PrivateRoute path='/deliverers' component={Deliverers} exact/>
            <PrivateRoute path='/info-client' component={InfoClient} exact/>
            <PrivateRoute path='/orders' component={Orders} exact/>
            <PrivateRoute path='/restaurants' component={Restaurants} exact/>
            <PrivateRoute path='/statistics' component={Statistics} exact/>
            <PrivateRoute path='/notifications' component={Notifications} exact/>
            <PrivateRoute path='/invoices' component={Invoices} exact/>
            <PrivateRoute path='/credit' component={Credit} exact/>
          </div>
          <Route component={NotFound}/>
        </Switch>
      </Router>
    </div>
  )
}
